import { env } from "../../env";
import { playAnotherAudio } from "../../player/playAnotherAudio";

export function ttsRadioIndoor(
  text: string,
  voice: string,
  onPlay: () => void,
  onEnded: () => void,
  beep: boolean
) {
  const url = "https://radioindoor.com.br/tts/";
  const formParams = encodeURIComponent(
    JSON.stringify({
      fabriccaradio: "1",
      texto: text,
      locutor: voice,
    })
  );
  const host = encodeURIComponent(env.tts.host);
  fetch(
    `${env.tts.host}/proxy.php?method=POST&host=${host}&url=${url}&form_params=${formParams}`
  )
    .then((response) => response.text())
    .then((result) => {
      console.log("proxy.php result:", result);
      if (beep) {
        playAnotherAudio(document.querySelector("#tts_audio")!, {
          src: "/beep.mp3",
          playMainAudioOnEnd: false,
          onPlay,
          onEnded: () => {
            playAnotherAudio(document.querySelector("#tts_audio")!, {
              src: result,
              playbackRate: window.MeuPlay.playbackRate,
              onEnded,
            });
          },
        });
      } else {
        playAnotherAudio(document.querySelector("#tts_audio")!, {
          src: result,
          playbackRate: window.MeuPlay.playbackRate,
          onPlay,
          onEnded,
        });
      }
    })
    .catch((error) => console.log("proxy.php error:", error));
}
